import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IApiResponse } from '../modelo/PageProps';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
  export class ChamadosExportacaoService {
      apiUrl: string = `${environment.backendUrl}/chamados/exportacao`;

      constructor(private http: HttpClient) { }

      httpOptions = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTMyNTI4MjcsImV4cCI6MTY5NTkzMTIyN30._btN2Ga17e2KE1JE3oOZiOIwt3V-bcS2HuXO0i6m27DtKEgIB6p2gkwU-tRD6bbBvjdsy30Voy0JLH5g27gL8Q'
          }
      }
      
      getExportacoes(dataInicial: string, dataFinal: string): Observable<Blob> {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json'
        });
        let options = {
          headers: headers,
          responseType: 'blob' as 'json' // necessário para forçar o TypeScript a aceitar 'blob' como um tipo de resposta válido
        };
      
        return this.http.post<Blob>(`${this.apiUrl}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, {}, options);
      }

  
  }