<app-menu-bar></app-menu-bar>
<div class="exportacao-container">
    <div style="text-align: center">Exportação de chamados</div>
    <p-toolbar>
        <ng-template pTemplate="right">
            <div class="card flex justify-content-center">
                <p-toast></p-toast>
            </div>
        </ng-template>
        <div>Período Inicial:</div>
        <p-calendar [(ngModel)]="startDate" dateFormat="dd-mm-yy"></p-calendar>
        <div>Período Final:</div>
        <p-calendar [(ngModel)]="endDate" dateFormat="dd-mm-yy"></p-calendar>
        <button pButton type="button" label="Exportar" (click)="exportData()"></button>
    </p-toolbar>
   

</div>