import { AuthService } from "../core/auth/service/auth.service";
import { Component } from "@angular/core";
import { ConfirmationService } from 'primeng/api';
import { DashboardService } from './dashboard.service';
import { MessageService } from "primeng/api";
import { PageProps } from '../modelo/PageProps';

const statusColorMap: { [key: string]: string } = {
    'Em andamento': 'orange',
    'Em Andamento': 'orange',
    'Encerrado': 'lightgreen',
    'Producao': 'lightblue',
    'Validação Cliente': 'lightblue',
    'Cancelado': 'darkred',
    'Suspenso': 'red'
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [MessageService, ConfirmationService]
})

export class DashboardComponent {


    apenasAtivos: boolean = true;
    chamados!: ResumoDashboard;
    escalations!: ResumoDashboard;
    lifeMessageToast: number = 7000;
    ProjetoPage: PageProps<ResumoDashboard> = {} as PageProps<ResumoDashboard>;
    projetos!: ResumoDashboard;

    constructor(
        private auth: AuthService,
        private messageService: MessageService,
        private service: DashboardService) {
    }
    backgroundColor = ["#FFFF00", "#FFD700", "#FF8C00", "#FF4500", "#B22222", "#8B4513"];

    escalationStatusData = {};
    escalationGrupoData = {};
    escalationProdutoData = {}
    escalationProdutoDuracaoData = {};
    escalationMensalData = {}

    chamadoStatusData = {};
    chamadoGrupoData = {};
    chamadoProdutoData = {}
    chamadoProdutoDuracaoData = {};
    chamadoMensalData = {}

    projetoStatusData = {};
    projetoGrupoData = {};
    projetoProdutoDuracaoData = {};
    projetoProdutoData = {}
    projetoMensalData = {};

    escalationStatusOptions = { plugins: {legend: { display: true,position: 'right' },
            title: {display: true, text: 'Escalation por Status', color: '#495057'}}};
    escalationGrupoOptions = { plugins: { legend: { display: false },
            title: {display: true, text: 'Escalation por Grupo', color: '#495057'}}};
    escalationProdutoDuracaoOptions = { plugins: { legend: { display: false },
        title: { display: true, text: 'Duração Média do Escalation (dias)', color: '#495057' }}};
    escalationProdutoOptions = { plugins: { legend: { display: false },
        title: { display: true, text: 'Escalation por Produto', color: '#495057' }}};
    escalationMensalOptions = {
      plugins: {legend: { display: true, position: 'top'}},
      scales: {
        x: {display: true, title: {display: true, text: 'Mês'}},
        y: {display: true, title: {display: true, text: 'Quantidade'}}}};

    chamadoStatusOptions = { plugins: {legend: { display: true, position: 'right'  },
            title: {display: true, text: 'Chamados por Status', color: '#495057'}}};
    chamadosGrupoOptions = { plugins: { legend: { display: false },
                title: {display: true, text: 'Chamados por Grupo', color: '#495057'}}};
    chamadosProdutoDuracaoOptions = { plugins: { legend: { display: false },
            title: { display: true, text: 'Duração Média do Chamado (dias)', color: '#495057' }}};
    chamadosProdutoOptions = { plugins: { legend: { display: false },
            title: { display: true, text: 'Chamados por Produto', color: '#495057' }}};
    chamadosMensalOptions = {
        plugins: {legend: { display: true, position: 'top'}},
        scales: {
          x: {display: true, title: {display: true, text: 'Mês'}},
          y: {display: true, title: {display: true, text: 'Quantidade'}}}};

    projetoStatusOptions = { plugins: {legend: { display: true, position: 'right' },
            title: { display: true, text: 'Projetos por Status', color: '#495057'}}};
    projetoGrupoOptions = { plugins: { legend: { display: false },
                title: {display: true, text: 'Projetos por Grupo', color: '#495057'}}};
    projetoProdutoDuracaoOptions = { plugins: { legend: { display: false },
            title: { display: true, text: 'Duração Média do Projeto (dias)', color: '#495057' }}};
    projetoProdutoOptions = { plugins: { legend: { display: false },
            title: { display: true, text: 'Projetos por Produto', color: '#495057' }}};
    projetoMensalOptions = {
        plugins: {legend: { display: true, position: 'top'}},
        scales: {
          x: {display: true, title: {display: true, text: 'Mês'}},
          y: {display: true, title: {display: true, text: 'Quantidade'}}}};

      async ngOnInit() {
        await this.fetchContent();
      }

      onCheckboxChange(event: any) {
        this.fetchContent();
      }

      public setDadosMensaisProjeto() {
        this.projetoMensalData = { labels: this.projetos.resumoMensalAberturaFechamento.labels, datasets: [
          {label: this.projetos.resumoMensalAberturaFechamento.datasets[0].label, data:
            this.projetos.resumoMensalAberturaFechamento.datasets[0].data,
            fill: true, borderColor: '#42A5F5', tension: 0.4},
          {label: this.projetos.resumoMensalAberturaFechamento.datasets[1].label,
            data: this.projetos.resumoMensalAberturaFechamento.datasets[1].data,
            fill: true, borderColor: '#FFA726', tension: 0.4}]};
      }

      public setDadosMensaisChamados() {
        this.chamadoMensalData = { labels: this.chamados.resumoMensalAberturaFechamento.labels, datasets: [
          {label: this.chamados.resumoMensalAberturaFechamento.datasets[0].label, data:
            this.chamados.resumoMensalAberturaFechamento.datasets[0].data,
            fill: true, borderColor: '#42A5F5', tension: 0.4},
          {label: this.chamados.resumoMensalAberturaFechamento.datasets[1].label,
            data: this.chamados.resumoMensalAberturaFechamento.datasets[1].data,
            fill: true, borderColor: '#FFA726', tension: 0.4}]};
      }

      public setDadosMensaisEscalations() {
        this.escalationMensalData = { labels: this.escalations.resumoMensalAberturaFechamento.labels, datasets: [
          {label: this.escalations.resumoMensalAberturaFechamento.datasets[0].label, data:
            this.escalations.resumoMensalAberturaFechamento.datasets[0].data,
            fill: true, borderColor: '#42A5F5', tension: 0.4},
          {label: this.escalations.resumoMensalAberturaFechamento.datasets[1].label,
            data: this.escalations.resumoMensalAberturaFechamento.datasets[1].data,
            fill: true, borderColor: '#FFA726', tension: 0.4}]};
      }

      public setDadosGrupoProjeto() {
        this.projetoGrupoData = { labels: this.projetos.resumoGrupo.labels,
        datasets: [{   data: this.projetos.resumoGrupo.datasets[0].data,
          borderColor: 'gray', backgroundColor: ['#90ee90','#83d890','#76c38f','#69ad8f','#5c978e','#4f828e','#416c8d','#34578d','#27418c','#1a2b8c','#0d168b','#00008b'],
            borderWidth: 2, borderSkipped: false}]};
      }

      public setDadosGrupoChamados() {
        this.chamadoGrupoData = { labels: this.chamados.resumoGrupo.labels,
          datasets: [{   data: this.chamados.resumoGrupo.datasets[0].data,
            borderColor: 'gray', backgroundColor: ['#90ee90','#83d890','#76c38f','#69ad8f','#5c978e','#4f828e','#416c8d','#34578d','#27418c','#1a2b8c','#0d168b','#00008b'],
              borderWidth: 2, borderSkipped: false}]};
      }

      public setDadosGrupoEscalations() {
        this.escalationGrupoData = { labels: this.escalations.resumoGrupo.labels,
          datasets: [{   data: this.escalations.resumoGrupo.datasets[0].data,
            borderColor: 'gray', backgroundColor: ['#90ee90','#83d890','#76c38f','#69ad8f','#5c978e','#4f828e','#416c8d','#34578d','#27418c','#1a2b8c','#0d168b','#00008b'],
              borderWidth: 2, borderSkipped: false}]};
      }

      public setDadosStatusProjeto() {
        let bgColorStatus: string[] = [];
        bgColorStatus = this.projetos.resumoStatus.labels.map((label: string) => {
            return statusColorMap[label] || 'defaultColor';
        });
        this.projetoStatusData = { labels: this.projetos.resumoStatus.labels,
        datasets: [{   data: this.projetos.resumoStatus.datasets[0].data,
            backgroundColor: bgColorStatus,
            borderWidth: 2, borderColor: 'gray', borderSkipped: false }]};
      }

      public setDadosStatusChamados() {
        let bgColorStatus: string[] = [];
        bgColorStatus = this.chamados.resumoStatus.labels.map((label: string) => {
            return statusColorMap[label] || 'defaultColor';
        });
        this.chamadoStatusData = {labels: this.chamados.resumoStatus.labels,
        datasets: [{   data: this.chamados.resumoStatus.datasets[0].data,
            backgroundColor: bgColorStatus,
            borderWidth: 2, borderColor: 'gray', borderSkipped: false}]};
      }

      public setDadosProdutoChamados() {
        this.chamadoProdutoData = { labels: this.chamados.resumoProduto.labels,
          datasets: [{   data: this.chamados.resumoProduto.datasets[0].data,
            borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
            borderWidth: 2, borderSkipped: false }]};
      }

      public setDadosProdutoEscalations() {
        this.escalationProdutoData = { labels: this.escalations.resumoProduto.labels,
          datasets: [{   data: this.escalations.resumoProduto.datasets[0].data,
            borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
            borderWidth: 2, borderSkipped: false }]};
      }

      public setDadosStatusEscalations() {
        let bgColorStatus: string[] = [];
        bgColorStatus = this.chamados.resumoStatus.labels.map((label: string) => {
            return statusColorMap[label] || 'defaultColor';
        });
        this.escalationStatusData = { labels: this.escalations.resumoStatus.labels,
        datasets: [{   data: this.escalations.resumoStatus.datasets[0].data,
          backgroundColor: bgColorStatus,
          borderWidth: 2, borderColor: 'gray', borderSkipped: false}]};
      }

      public setDadosProdutoProjeto() {
        this.projetoProdutoData = { labels: this.projetos.resumoProduto.labels,
        datasets: [{   data: this.projetos.resumoProduto.datasets[0].data,
          borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
          borderWidth: 2, borderSkipped: false }]};
      }

      public setDadosDuracaoProjeto() {
        this.projetoProdutoDuracaoData = { labels: this.projetos.resumoDuracaoMediaProduto.labels,
        datasets: [{   data: this.projetos.resumoDuracaoMediaProduto.datasets[0].data,
          borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
          borderWidth: 2, borderSkipped: false }]};
      }

      public setDadosDuracaoChamados() {
        this.chamadoProdutoDuracaoData = { labels: this.chamados.resumoDuracaoMediaProduto.labels,
          datasets: [{   data: this.chamados.resumoDuracaoMediaProduto.datasets[0].data,
            borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
            borderWidth: 2, borderSkipped: false }]};
      }

      public setDadosDuracaoEscalations() {
        this.escalationProdutoDuracaoData = { labels: this.escalations.resumoDuracaoMediaProduto.labels,
          datasets: [{   data: this.escalations.resumoDuracaoMediaProduto.datasets[0].data,
            borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
            borderWidth: 2, borderSkipped: false }]};
      }

      public fetchContent(): void {
        if (!this.auth.isAuthenticated())
          return;
        this.service.getDadosProjeto(this.apenasAtivos).subscribe(
            (response: any) => {
                this.projetos = response;
                this.setDadosStatusProjeto();
                this.setDadosGrupoProjeto();
                this.setDadosProdutoProjeto();
                this.setDadosDuracaoProjeto();
                this.setDadosMensaisProjeto();
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de projetos!',
                    life: this.lifeMessageToast,
                  });
            }
          );
          this.service.getDadosChamado(this.apenasAtivos).subscribe(
            (response: any) => {
                this.chamados = response;
                this.setDadosStatusChamados();
                this.setDadosProdutoChamados();
                this.setDadosGrupoChamados();
                this.setDadosDuracaoChamados();
                this.setDadosMensaisChamados();
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de chamados!',
                    life: this.lifeMessageToast,
                  });
            }
          );
          this.service.getDadosEscalation(this.apenasAtivos).subscribe(
            (response: any) => {
                this.escalations = response;
                this.setDadosStatusEscalations();
                this.setDadosProdutoEscalations();
                this.setDadosGrupoEscalations();
                this.setDadosDuracaoEscalations();
                this.setDadosMensaisEscalations();
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de escalations!',
                    life: this.lifeMessageToast,
                  });
            }
          );
      }
}

interface Dataset {
    label: string;  // Permite strings ou null
    data: number[];        // Array de números
}

interface ResumoDashboard {
    resumoStatus: {
        labels: string[];
        datasets: Dataset[];
    };
    resumoMensalAberturaFechamento: {
        labels: string[];
        datasets: Dataset[];
    };
    resumoGrupo: {
        labels: string[];
        datasets: Dataset[];
    };
    resumoProduto: {
        labels: string[];
        datasets: Dataset[];
    };
    resumoDuracaoMediaProduto: {
        labels: string[];
        datasets: Dataset[];
    }
}
