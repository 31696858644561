import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ChamadosExportacaoService } from './exportacao-chamados.service';

@Component({
    selector: 'app-chamados-exportacao',
    templateUrl: './exportacao-chamados.component.html',
    styleUrls: ['./exportacao-chamados.component.css'],
    providers: [MessageService, ConfirmationService],
  })
  export class ChamadosExportacaoComponent {

    startDate?: Date;
    endDate?: Date;

    constructor(
      private service: ChamadosExportacaoService,
      private http: HttpClient) {
      this.initializeDates();
    }
  
    initializeDates() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
  
      // Definindo o primeiro dia do mês anterior
      this.startDate = new Date(currentYear, currentMonth - 1, 1);
  
      // Encontrando o último dia do mês anterior
      this.endDate = new Date(currentYear, currentMonth, 0);
    }

    exportData() {
        this.service.getExportacoes(
          this.formatDate(this.startDate), 
          this.formatDate(this.endDate))
          .subscribe(response => {
          console.log('Dados: ' + response);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.download = 'exportacao_chamados_' + 
            new Date().toLocaleDateString() + '_' + 
            new Date().toLocaleTimeString() + '.xlsx'
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url); 
        })
    }    

    private formatDate(date?: Date): string {
      if (date == undefined)
        return "";
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).split('/').reverse().join('-');
    }
  
  }