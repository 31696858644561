import { Comentario } from '../modelo/Comentario';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IApiResponse } from '../modelo/PageProps';
import { Projeto } from '../modelo/Projeto';
import { Observable } from 'rxjs';
import { Upload } from '../modelo/Upload';
import { RegraTipoData } from '../modelo/RegraTipoData';
import { Regra } from '../modelo/Regra';

@Injectable({
  providedIn: 'root'
})
export class RegrasAlertaService {

  apiUrl: string = `${environment.backendUrl}/regras`;

  constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    httpOptionsMultiPart = {
      headers: {
          //'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
      }
  }

    getSolucoes(): Observable<string[]> {
      let searchParams = new HttpParams();
      return this.http.get<string[]>(`${this.apiUrl}/listaSolucoes`,
        { params: searchParams, headers: this.httpOptions.headers });
    }

    getDtBase(): Observable<String[]> {
      return this.http.get<String[]>(`${this.apiUrl}/listaTipoData`);
    }

    getCondicoes(): Observable<String[]> {
      return this.http.get<String[]>(`${this.apiUrl}/listaCondicoes`);
    }

    getFrequencias(): Observable<String[]> {
      return this.http.get<String[]>(`${this.apiUrl}/listaFrequencia`);
    }

    getRegras(pageNumber: number, pageSize: number){
      let searchParams = new HttpParams();

      searchParams = searchParams.append(`page`, String(pageNumber));
      searchParams = searchParams.append(`size`, String(pageSize));

      return this.http.get<IApiResponse>(`${this.apiUrl}`,
      { params: searchParams, headers: this.httpOptions.headers });
    }

    salvarRegra(regra: Regra): Observable<any> {
      return this.http.post(`${this.apiUrl}`, regra, { headers: this.httpOptions.headers });
    }

    editarRegra( regra: Regra): Observable<any>{
      return this.http.put(`${this.apiUrl}/${regra.id}`,
      regra, { headers: this.httpOptions.headers });
    }

    deletarRegra(regra: Regra): Observable<any> {
      return this.http.delete(`${this.apiUrl}/${regra.id}`, this.httpOptions);
    }


}
