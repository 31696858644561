import { Grupo } from "./Grupo"
import { Status } from "./Status"

export class Projeto {

public id?: number
public grupo!: Grupo
public cliente?: string
public solucao?: string
public escopo?: string
public origem?: string
public ativacao?: string
public dtcadastro?: Date = new Date;
public dtcontrato?: Date
public dtkickoff?: Date
public dttestunit?: Date
public dttestinteg?: Date
public dtgolive?: Date
public status?: Status
public statusDescricao?: string
public statusAndamento?: string
public dtpromocao?: Date
public qtdocorrenciaspendentes?: number

}
