<app-menu-bar></app-menu-bar>
<div class="extracoes-container">
    <div style="text-align: center">Relatório Analítico de Projetos</div>
    <p-toolbar>
        <ng-template pTemplate="right">
            <div class="card flex justify-content-center">
                <p-toast></p-toast>
            </div>
            <button pButton pRipple label="Processar" (click)="getanalitico()"></button>
        </ng-template>
    </p-toolbar>
    <div class="table-container">
        <p-table
            [value]="projetos" 
            [paginator]="true"
            [rows]="15"    
            [(selection)]="projetosSelecionados" dataKey="id"
            styleClass="p-datatable-gridlines"
            [tableStyle]="{ 'min-width': '50rem' }"
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
            [globalFilterFields]="[
            'dados.grupo',
            'dados.cliente',
            'dados.solucao',
            'dados.escopo',
            'dados.erp',
            'dados.ativacao',
            'dados.dtcontrato'
            ]">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3%; text-align: center;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="text-align: center ">Grupo</th>
                    <th style="text-align: center">Cliente</th>
                    <th style="text-align: center">Solução</th>
                    <th style="text-align: center">Escopo</th>
                    <th style="text-align: center">ERP</th>
                    <th style="text-align: center">Ativação</th>
                    <th style="text-align: center">Data Contrato</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>
                  <td style="text-align: center;">
                    <p-tableCheckbox  [value]="dados"></p-tableCheckbox>
                  </td>
                  <td style="text-align: center">{{ dados.grupo.nome }}</td>
                  <td style="text-align: center">{{ dados.cliente }}</td>
                  <td style="text-align: center">{{ dados.solucao }}</td>
                  <td style="text-align: center">{{ dados.escopo }}</td>
                  <td style="text-align: center">{{ dados.origem }}</td>
                  <td style="text-align: center">{{ dados.ativacao }}</td>
                  <td style="text-align: center">{{ dados.dtcontrato | date:'yyyy-MM-dd'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
