<app-menu-bar></app-menu-bar>
<div class="˜ocorrencia-container">
    <div style="text-align: center">Ocorrencias{{ complemento }} </div>
    <div class="table-container">
        <p-table  
        [value]="ocorrencias"
        [paginator]="true"
        [rows]="15"
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="dtOcorrencia">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Data
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="dtOcorrencia"></p-sortIcon>
                        <p-columnFilter
                          type="text"
                          field="dtOcorrencia"
                          display="menu"
                          matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>
                <th pSortableColumn="grupo.nome">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Grupo
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="grupo.nome"></p-sortIcon>
                        <p-columnFilter
                          type="text"
                          field="grupo.nome"
                          display="menu"
                          matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        
                <th pSortableColumn="projeto.cliente">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Cliente
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="projeto.cliente"></p-sortIcon>
                        <p-columnFilter
                          type="text"
                          field="projeto.cliente"
                          display="menu"
                          matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        
                <th pSortableColumn="projeto.solucao">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Soluçao
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="projeto.solucao"></p-sortIcon>
                        <p-columnFilter type="text" field="projeto.solucao" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        
                <th pSortableColumn="projeto.origem">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        ERP
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="projeto.origem"></p-sortIcon>
                        <p-columnFilter field="projeto.origem" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        
                <th pSortableColumn="regra.dtbase">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Data Base
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="regra.dtbase"></p-sortIcon>
                        <p-columnFilter field="regra.dtbase" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        
                <th pSortableColumn="regra.condicao">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Condiçao
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="regra.condicao"></p-sortIcon>
                        <p-columnFilter field="regra.condicao" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        

                <th pSortableColumn="regra.dtauxiliar">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Data Auxiliar
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="regra.dtauxiliar"></p-sortIcon>
                        <p-columnFilter field="regra.dtauxiliar" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        

                <th pSortableColumn="regra.valor">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Valor
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="regra.valor"></p-sortIcon>
                        <p-columnFilter field="regra.valor" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        

                <th pSortableColumn="valores">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Ocorrencia
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="valores"></p-sortIcon>
                        <p-columnFilter field="valores" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        

                <th pSortableColumn="justificativa">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Justificativa
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="justificativa"></p-sortIcon>
                        <p-columnFilter field="justificativa" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        

                <th pSortableColumn="notificado">
                    <div class="flex justify-content-between align-items-center">
                      <div class="flex justify-content-between align-items-center">
                        Notificado
                      </div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="notificado"></p-sortIcon>
                        <p-columnFilter field="notificado" display="menu" matchMode="contains"></p-columnFilter>
                      </div>
                    </div>
                </th>        

                <th pSortableColumn="emailEnvio">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      Emails Notificação
                    </div>
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="emailEnvio"></p-sortIcon>
                      <p-columnFilter field="emailEnvio" display="menu" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
              </th>        

                <th style="text-align: center">Operação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dados>
            <tr>
                <td style="text-align: left">
                    {{ dados.dtOcorrencia | date : "yyyy-MM-dd" }}</td>
                <td style="text-align: left">{{ dados.grupo.nome }}</td>
                <td style="text-align: left">{{ dados.projeto.cliente }}</td>        
                <td style="text-align: left">{{ dados.projeto.solucao }}</td>        
                <td style="text-align: left">{{ dados.projeto.origem }}</td>        
                <td style="text-align: left">{{ dados.dtbaseDescricao }}</td>        
                <td style="text-align: left">{{ dados.condicaoDescricao }}</td>        
                <td style="text-align: left">{{ dados.dtauxiliarDescricao }}</td>        
                <td style="text-align: left">{{ dados.regra.valor }}</td>
                <td style="text-align: left">{{ dados.valores }}</td>
                <td style="text-align: left">{{ dados.justificativa }}</td>
                <td style="text-align: center">{{ dados.notificado }}</td>
                <td style="text-align: center">{{ dados.emailEnvio }}</td>
                <td style="text-align: center">
                    <p-button
                    icon="pi pi-file-edit"
                    styleClass="p-button-rounded p-button-secondary p-button-text p-button-success"
                    pTooltip="Justificar"
                    (click)="addEdit(dados)"></p-button>
                    <p-button
                    icon="pi pi-envelope"
                    styleClass="p-button-rounded p-button-secondary p-button-text p-button-info"
                    pTooltip="Notificar
                    novamente"
                    (click)="notificarNovamente(dados)"></p-button>
                </td>
            </tr>
          </ng-template>
        </p-table>

        <p-confirmDialog
            appendTo="body"
            icon="pi pi-exclamation-triangle"
            [position]="'top'"
            [baseZIndex]="10000"></p-confirmDialog>
        <p-dialog
            header="Ocorrencia"
            [(visible)]="visible"
            [style]="{ width: '1000px', height:'500px' }"
            [modal]="true"
            (onHide)="hideDialog()"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="content-container">
                    <div class="textarea-container">
                        <textarea rows="15" cols="130" [(ngModel)]="ocorrencia.justificativa" class="custom-textarea"></textarea>
                        <small class="p-error" *ngIf="submitted && !ocorrencia.justificativa">Preencher campo!
                        </small>            
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button
                  pButton
                  label="Salvar"
                  icon="pi pi-check"
                  class="b-salvar"
                  (click)="salvarJustificativa()"></button>
                <p-button
                  pRipple
                  label="Cancelar"
                  icon="pi pi-times"
                  class="b-cancelar"
                  styleClass="p-button-secondary"
                  (click)="hideDialog()"></p-button>
              </ng-template>
        
        </p-dialog>
        


    </div>
</div>