import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChamadosCargaService {

  apiUrl: string = `${environment.backendUrl}/chamados`;

  constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    httpOptionsMultiPart = {
      headers: {
          //'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
      }
  }

    uploadFile(file: File){
      const formData = new FormData();
        formData.append('file', file);
      return this.http.post(`${this.apiUrl}`,
      formData, { headers: this.httpOptionsMultiPart.headers ,  observe: 'response', responseType: 'text' });
    }

    getProcessamentos(){
      let searchParams = new HttpParams();
      return this.http.get<IApiResponse>(`${this.apiUrl}/processamento`,
      { params: searchParams, headers: this.httpOptions.headers });
    }

    getErros(id: number) {
      let searchParams = new HttpParams();
      return this.http.get<IApiResponse>(`${this.apiUrl}/erros/${id}`,
      { params: searchParams, headers: this.httpOptions.headers });
    }


}
