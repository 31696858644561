import { Grupo } from "./Grupo"
import { Projeto } from "./Projeto"
import { Regra } from "./Regra"

export class Ocorrencia {

    public id?: number
    public dtOcorrencia?: Date
    public grupo!: Grupo
    public projeto!: Projeto
    public regra!: Regra
    public valores?: string
    public justificativa?: string
    public notificado?: boolean
    public dtbaseDescricao?: string
    public dtauxiliarDescricao?: string
    public condicaoDescricao?: string
    public valoresHtml?: string
    public emailEnvio?: string

}