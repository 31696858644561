<app-menu-bar></app-menu-bar>
<div class="escalation-container">
    <div style="text-align: center">Consulta de Escalations</div>
    <p-toolbar>
        <ng-template pTemplate="right">
            <div class="card flex justify-content-center">
                <p-toast></p-toast>
                <button pButton pRipple icon="pi pi-plus" label="Adicionar" class="p-button-info mr-2" (click)="add()"></button>
            </div>
        </ng-template>
    </p-toolbar>

    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="dados"
        [paginator]="true"
        [rows]="15"
        [rowsPerPageOptions]="[10, 15, 20]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">
                        <div class="flex justify-content-between align-items-center">ID</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="id"></p-sortIcon>
                            <p-columnFilter field="id" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="cliente">
                        <div class="flex justify-content-between align-items-center">Cliente</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="cliente"></p-sortIcon>
                            <p-columnFilter field="cliente" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="grupo.nome">
                        <div class="flex justify-content-between align-items-center">Grupo</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="grupo.nome"></p-sortIcon>
                            <p-columnFilter field="grupo.nome" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="usuarioLogado">
                        <div class="flex justify-content-between align-items-center">Responsável</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="usuarioLogado"></p-sortIcon>
                            <p-columnFilter field="usuarioLogado" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="titulo">
                      <div class="flex justify-content-between align-items-center">Titulo</div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="titulo"></p-sortIcon>
                        <p-columnFilter field="titulo" display="menu" ></p-columnFilter>
                      </div>
                    </th>
                    <th pSortableColumn="produto">
                        <div class="flex justify-content-between align-items-center">Produto</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="produto"></p-sortIcon>
                            <p-columnFilter field="produto" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="modulo">
                        <div class="flex justify-content-between align-items-center">Módulo</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="modulo"></p-sortIcon>
                            <p-columnFilter field="modulo" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="problemaRelatado">
                        <div class="flex justify-content-between align-items-center">Problema Relatado</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="problemaRelatado"></p-sortIcon>
                            <p-columnFilter field="problemaRelatado" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="retornoSuporte">
                      <div class="flex justify-content-between align-items-center">Retorno Suporte</div>
                      <div class="flex justify-content-between align-items-center">
                        <p-sortIcon field="retornoSuporte"></p-sortIcon>
                        <p-columnFilter field="retornoSuporte" display="menu" ></p-columnFilter>
                      </div>
                    </th>
                    <th pSortableColumn="causaRaiz">
                        <div class="flex justify-content-between align-items-center">Histórico do Processo</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="causaRaiz"></p-sortIcon>
                            <p-columnFilter field="causaRaiz" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="impacto">
                        <div class="flex justify-content-between align-items-center">Impacto</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="impacto"></p-sortIcon>
                            <p-columnFilter field="impacto" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="classeRCA">
                        <div class="flex justify-content-between align-items-center">Classe RCA</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="classeRCA"></p-sortIcon>
                            <p-columnFilter field="classeRCA" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="accountId">
                        <div class="flex justify-content-between align-items-center">Account Id</div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="accountId"></p-sortIcon>
                            <p-columnFilter field="accountId" display="menu" ></p-columnFilter>
                        </div>
                    </th>
                    <th>Operações</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>
                    <td style="text-align: center">{{ dados.id }} </td>
                    <td style="text-align: center">{{ dados.cliente }} </td>
                    <td style="text-align: center">{{ dados.grupo.nome }} </td>
                    <td style="text-align: center">{{ dados.usuarioLogado }} </td>
                    <td style="text-align: center">{{ dados.titulo }} </td>
                    <td style="text-align: center">{{ dados.produto }} </td>
                    <td style="text-align: center">{{ dados.modulo }} </td>
                    <td style="text-align: center">{{ dados.problemaRelatado }} </td>
                    <td style="text-align: center">{{ dados.retornoSuporte }} </td>
                    <td style="text-align: center">{{ dados.causaRaiz }} </td>
                    <td style="text-align: center">{{ dados.impacto }} </td>
                    <td style="text-align: center">{{ dados.classeRCA }} </td>
                    <td style="text-align: center">{{ dados.accountId }} </td>
                    <td style="text-align: center">
                        <i class="pi pi-trash" style="color:red" pTooltip="Excluir"
                        tooltipPosition="left" (click)="Excluir(dados)">&nbsp;</i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-dialog header="Requisição de Escalation" [(visible)]="visible" [style]="{ width: '900px', height: '800px' }"
            [modal]="true" (onHide)="hideDialog()" styleClass="p-fluid">
            <ng-template pTemplate="header">
                <h2>Requisição de Escalation</h2>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="grid">
                    <div class="p-field col-6 p-fluid">
                        <label for="cliente">Cliente:</label>
                        <input type="text" pInputText id="cliente" [(ngModel)]="dadosEscalation.cliente"/>
                        <small class="p-error" *ngIf="!dadosEscalation.cliente">Cliente é obrigatório.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                      <label for="titulo">Titulo:</label>
                      <input type="text" pInputText id="titulo" [(ngModel)]="dadosEscalation.titulo"/>
                      <small class="p-error" *ngIf="!dadosEscalation.titulo">Titulo é obrigatório.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="produto">Produto:</label>
                        <p-dropdown [options]="solucoes" [(ngModel)]="dadosEscalation.produto" placeholder="Selecione uma opção"></p-dropdown>
                        <small class="p-error" *ngIf="!dadosEscalation.produto">Produto é obrigatório.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="problemaRelatado">Problema Relatado:</label>
                        <input type="text" pInputText id="problemaRelatado" [(ngModel)]="dadosEscalation.problemaRelatado"/>
                        <small class="p-error" *ngIf="!dadosEscalation.problemaRelatado">Descrição é obrigatória.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="chamadoAvalara">Chamado(s) Avalara:</label>
                        <input type="text" pInputText id="chamadoAvalara" [(ngModel)]="dadosEscalation.chamadoAvalara"/>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="modulo">Módulo:</label>
                        <input type="text" pInputText id="modulo" [(ngModel)]="dadosEscalation.modulo"/>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="name">Impacto:</label>
                        <p-dropdown [options]="optionsImpacto" [(ngModel)]="dadosEscalation.impacto" placeholder="Selecione uma opção"></p-dropdown>
                        <small class="p-error" *ngIf="!dadosEscalation.impacto">Impacto é obrigatório.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="name">Classe RCA:</label>
                        <p-dropdown [options]="optionsClasseRCA" [(ngModel)]="dadosEscalation.classeRCA" placeholder="Selecione uma opção"></p-dropdown>
                        <small class="p-error" *ngIf="!dadosEscalation.classeRCA">Classe RCA é obrigatório.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                      <label for="retornoSuporte">Qual retorno após escalação com o time do suporte?</label>
                      <input type="text" pInputText id="retornoSuporte" [(ngModel)]="dadosEscalation.retornoSuporte"/>
                    </div>
                    <div class="p-field col-6 p-fluid">
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="name">Historico do Processo ate escalaçao (breve resumo):</label>
                        <textarea rows="8" cols="100" [(ngModel)]="dadosEscalation.causaRaiz" class="custom-textarea"></textarea>
                        <small class="p-error" *ngIf="!dadosEscalation.causaRaiz">Causa Raiz é obrigatória.</small>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton label="Salvar" icon="pi pi-check" class="b-salvar" (click)="inserirEscalation()"></button>
                <p-button pRipple label="Cancelar" icon="pi pi-times" class="b-cancelar" styleClass="p-button-secondary" (click)="hideDialog()"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
