<app-menu-bar></app-menu-bar>
<div class="chamados-container">
    <div style="text-align: center">Consulta de Dados</div>
    <p-toolbar>
        <ng-template pTemplate="right">
            <div class="card flex justify-content-center">
                <p-toast></p-toast>
            </div>
        </ng-template>
    </p-toolbar>

    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="dados"
        [paginator]="true"
        [rows]="15"
        [rowsPerPageOptions]="[10, 15, 20]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
        [globalFilterFields]="[
        'dados.id',
        'dados.chamado',
        'dados.data',
        'dados.status',
        'dados.cliente',
        'dados.produtos']">

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            ID
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="id"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="id"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="grupo.nome">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Grupo
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="grupo.nome"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="grupo.nome"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="chamadoOriginal">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Chamado N1
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="chamadoOriginal"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="chamadoOriginal"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="chamadoAvalara">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Chamado Avalara
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="chamadoAvalara"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="chamadoAvalara"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="dataAbertura">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Abertura
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataAbertura"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="dataAbertura"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="dataFechamento">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Fechamento
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataFechamento"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="dataFechamento"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="status">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Status
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="status"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="status"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="cliente"  style="width: 200pt">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Cliente
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="cliente"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="cliente"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="produto">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Produto
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="produto"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="produto"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>
                <th style="text-align: center">Dados Complementares</th>
                <th style="text-align: center">Operação</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-dados>
            <tr>
                <td style="text-align: center">{{ dados.id }} </td>
                <td style="text-align: center">{{ dados.grupo.nome }}</td>
                <td style="text-align: center">{{ dados.chamadoOriginal }}</td>
                <td style="text-align: center">{{ dados.chamadoAvalara }}</td>
                <td style="text-align: center">{{ dados.dataAbertura | date : "yyyy-MM-dd"  }}</td>
                <td style="text-align: center">{{ dados.dataFechamento | date: "yyyy-MM-dd" }}</td>

                <td style="text-align: center">{{ dados.status }}</td>
                <td style="text-align: center">{{ dados.cliente}}</td>
                <td style="text-align: center">{{ dados.produto }}</td>
                <td style="text-align: center">
                    <i class="pi pi-user" style="color:gray"
                        pTooltip="Consultor(a): {{ dados.consultor }}" tooltipPosition="left">&nbsp;</i>
                    <i class="pi pi-exclamation-circle" style="color:gray"
                        pTooltip="Problema Relatado: {{ dados.problemaRelatado }}" tooltipPosition="left">&nbsp;</i>
                    <i class="pi pi-check" style="color:gray"
                        pTooltip="Solução Enviada: {{ dados.solucaoEnviada }}" tooltipPosition="left">&nbsp;</i>
                    <i class="pi pi-calendar" style="color: green"
                        pTooltip="Data Cadastro: {{ dados.dataCadastro }}" tooltipPosition="left">&nbsp;</i>
                </td>
                <td style="text-align: center">
                  <!--
                    <i class="pi pi-ban" style="color: orange" pTooltip="Solicitar Escalation"
                        tooltipPosition="left" (click)="EscalationChamado(dados)">&nbsp;</i>
                    -->
                    <i class="pi pi-trash" style="color:red" pTooltip="Excluir chamado"
                    tooltipPosition="left" (click)="ExcluirChamado(dados)">&nbsp;</i>
                </td>
            </tr>
        </ng-template>
        </p-table>
        <p-dialog header="Requisição de Escalation" [(visible)]="visible" [style]="{ width: '900px', height: '790px' }"
            [modal]="true" (onHide)="hideDialog()" styleClass="p-fluid">
            <ng-template pTemplate="header">
                <h2>Requisição de Escalation</h2>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="grid">
                    <div class="p-field col-6 p-fluid">Cliente:<BR>&nbsp;{{dadoChamado.cliente}}</div>
                    <div class="p-field col-6 p-fluid">Produto:<BR>&nbsp;{{dadoChamado.produto}} </div>
                    <div class="p-field col-6 p-fluid">Responsável:<BR>&nbsp;{{usuarioLogado}}</div>
                    <div class="p-field col-6 p-fluid">Descrição:<BR>&nbsp;{{dadoChamado.problemaRelatado}}</div>
                    <div class="p-field col-6 p-fluid">Chamado Avalara:<BR>&nbsp;{{dadoChamado.chamadoAvalara}}</div>
                    <div class="p-field col-6 p-fluid"></div>
                    <div class="p-field col-6 p-fluid">
                        <label for="modulo">Módulo:</label>
                        <input type="text" pInputText id="modulo" [(ngModel)]="dadoChamado.modulo"/>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="impacto">Impacto:</label>
                        <p-dropdown id="impacto" [options]="optionsImpacto" [(ngModel)]="dadoChamado.impacto" placeholder="Selecione uma opção"></p-dropdown>
                        <small class="p-error" *ngIf="!dadoChamado.impacto">Impacto é obrigatório.</small>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="classeRCA">Classe RCA:</label>
                        <p-dropdown id="classeRCA" [options]="optionsClasseRCA" [(ngModel)]="dadoChamado.classeRCA" placeholder="Selecione uma opção"></p-dropdown>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="retornoSuporte">Qual retorno você teve após escalação com o time do suporte?</label>
                        <input type="text" pInputText id="retornoSuporte" [(ngModel)]="dadoChamado.retornoSuporte"/>
                    </div>
                    <div class="p-field col-6 p-fluid">
                        <label for="causaRaiz">Historico do Processo ate escalaçao (breve resumo)</label>
                        <textarea id="causaRaiz" rows="8" cols="100" [(ngModel)]="dadoChamado.causaRaiz" class="custom-textarea"></textarea>
                        <small class="p-error" *ngIf="!dadoChamado.causaRaiz">Causa Raiz é obrigatória.</small>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton label="Salvar" icon="pi pi-check" class="b-salvar" (click)="inserirEscalation()"></button>
                <p-button pRipple label="Cancelar" icon="pi pi-times" class="b-cancelar" styleClass="p-button-secondary" (click)="hideDialog()"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
