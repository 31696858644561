import { RegraCondicao } from "./RegraCondicao"
import { RegraTipoData } from "./RegraTipoData"
import { RegraFrequencia } from "./RegraFrequenncia"
import { Status } from "./Status"

export class Regra {

public id?: number
public solucao?: string
public dtbase?: RegraTipoData
public condicao?: RegraCondicao
public dtauxiliar?: RegraTipoData
public valor?: number
public status?: Status
public statusDescricao?: string
public frequencia?: RegraFrequencia
public qtdalertas?: number
public informativo?: string
public dtbaseDescricao?: string
public dtauxiliarDescricao?: string
public frequenciaDescricao?: string
public condicaoDescricao?: string

}
