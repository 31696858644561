import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IApiResponse } from '../modelo/PageProps';
import { Observable } from 'rxjs';
import { Projeto } from '../modelo/Projeto';

@Injectable({
  providedIn: 'root'
})
export class ExportacoesService {
    apiUrl: string = `${environment.backendUrl}/exportacao`;

  constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTMyNTI4MjcsImV4cCI6MTY5NTkzMTIyN30._btN2Ga17e2KE1JE3oOZiOIwt3V-bcS2HuXO0i6m27DtKEgIB6p2gkwU-tRD6bbBvjdsy30Voy0JLH5g27gL8Q'
        }
    }

    getExportacoes ( projetos: Projeto[]): Observable<Blob>{
      let searchParams = new HttpParams();

      return this.http.post<any>(`${this.apiUrl}`, projetos,
      { responseType: 'blob' as 'json' });
    }

}