import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiUrl: string = `${environment.backendUrl}/dashboard`;
  constructor(private http: HttpClient) { }
    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

     httpOptionsMultiPart = {
       headers: {
           //'Content-Type': 'application/json',
           'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
       }
   }

   getDadosProjeto(apenasAtivos: boolean): any {
       let searchParams = new HttpParams();
       return this.http.get<string[]>(`${this.apiUrl}/projetos?apenasAtivos=${apenasAtivos}`,
         { params: searchParams, headers: this.httpOptions.headers });
     }

    getDadosChamado(apenasAtivos: boolean): any {
      let searchParams = new HttpParams();
      return this.http.get<string[]>(`${this.apiUrl}/chamados?apenasAtivos=${apenasAtivos}`,
        { params: searchParams, headers: this.httpOptions.headers });
    }

    getDadosEscalation(apenasAtivos: boolean): any {
      let searchParams = new HttpParams();
      return this.http.get<string[]>(`${this.apiUrl}/escalations?apenasAtivos=${apenasAtivos}`,
        { params: searchParams, headers: this.httpOptions.headers });
    }



}
