    <app-menu-bar></app-menu-bar>
    <div class="chamados-container">
        <div style="text-align: center">Carga de Chamados</div>
        <p-toolbar>
            <ng-template pTemplate="right">
                <div class="card flex justify-content-center">
                    <p-toast></p-toast>
                </div>
                <button pButton pRipple icon="pi pi-paperclip" label="Upload" pTooltip="Subida da planilha Excel com os chamados. Layout pre-definido"
                class="p-button-info mr-2" (click)="openUpload()">
            </button>
        </ng-template>
    </p-toolbar>

    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="processamentos"
        [paginator]="true"
        [rows]="15"
        [globalFilterFields]="[
        'dados.id',
        'dados.data',
        'dados.nomeArquivo',
        'dados.qtdRegistro',
        'dados.qtdErros',
        'dados.status',
        'dados.usuarioResponsavel',
        'dados.grupo.nome']">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            ID
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="id"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="id"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="dataProcessamento">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Data
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataProcessamento"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="dataProcessamento"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="nomeArquivo">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Nome Arquivo
                        </div>
                        <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="nomeArquivo"></p-sortIcon>
                                <p-columnFilter
                                type="text"
                                field="nomeArquivo"
                                display="menu"
                                matchMode="contains"
                                [showMatchModes]="true"
                                [showOperator]="true"
                                [showAddButton]="true">
                                </p-columnFilter>
                            </div>
                    </div>
                </th>

                <th pSortableColumn="qtdRegistros">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Qtd.Registros
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="qtdRegistro"></p-sortIcon>
                            <p-columnFilter
                            type="int"
                            field="qtdRegistros"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>
                <th pSortableColumn="qtdErros">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Qtd.Erros
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="qtdErros"></p-sortIcon>
                            <p-columnFilter
                            type="int"
                            field="qtdErros"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>
                <th pSortableColumn="status">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Status
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="status"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="status"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>

                <th pSortableColumn="usuarioResponsavel">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Usuário
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="usuarioResponsavel"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="usuarioResponsavel"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>
                <th pSortableColumn="grupo.nome">
                    <div class="flex justify-content-between align-items-center">
                        <div class="flex justify-content-between align-items-center">
                            Grupo
                        </div>
                        <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="grupo.nome"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                            field="grupo.nome"
                            display="menu"
                            matchMode="contains"
                            [showMatchModes]="true"
                            [showOperator]="true"
                            [showAddButton]="true"
                            ></p-columnFilter>
                        </div>
                    </div>
                </th>
                <th style="text-align: center">Operação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dados>
            <tr>
                <td style="text-align: center">{{ dados.id }} </td>
                <td style="text-align: center">{{ dados.dataProcessamento  | date : "yyyy-MM-dd HH:mm:ss"  }}</td>
                <td style="text-align: center">{{ dados.nomeArquivo }}</td>
                <td style="text-align: center">{{ dados.qtdRegistros }}</td>
                <td style="text-align: center">{{ dados.qtdErros }}</td>
                <td style="text-align: center">{{ dados.status}}</td>
                <td style="text-align: center">{{ dados.usuarioResponsavel }}</td>
                <td style="text-align: center">{{ dados.grupo.nome }}</td>
                <td style="text-align: center">
                    <i *ngIf="dados.status != 'Sucesso'" class="pi pi-exclamation-triangle" style="color:red" pTooltip="Detalhamento dos Erros" tooltipPosition="left" (click)="listaErros(dados)">&nbsp;</i>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>

    <!-- Dialog de inserir anexo -->
    <p-dialog header="Upload Planilha de Chamados" [(visible)]="uploadDialog" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div>
                <div class="upload-container">
                    <p-fileUpload name="file[]" [customUpload]="true" (uploadHandler)="uploadFile($event)"
                    (onUpload)="uploadFile($event)" [multiple]="true" accept [maxFileSize]="10000000"
                    chooseLabel="Selecionar Arquivo" cancelLabel="Cancelar" uploadLabel="Enviar"
                    uploadIcon="pi pi-cloud-upload">
                    <ng-template pTemplate="content">
                        <ul *ngIf="uploadFiles.length">
                            <li *ngFor="let file of uploadFiles">
                                {{ file.name }} - {{ file.size }} bytes
                            </li>
                        </ul>
                    </ng-template>
                    </p-fileUpload>
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <!-- Dialog de Listagem de Erros -->
    <p-dialog header="Listagem de Erros de Processamento" [(visible)]="errosDialog" [modal]="true" styleClass="p-fluid"
        [style]="{ width: '1200px', height:'500px' }">
        <ng-template pTemplate="content">
            <div>
                <div class="table-container">
                    <p-table [value]="erros" [globalFilterFields]="['detalhe']" >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Linha</th>
                                <th>Registro</th>
                                <th>Mensagem de Erros</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-dados let-rowIndex="rowIndex">
                            <tr *ngIf="shouldShowValue(rowIndex)" style="height: 1px">
                              <td></td>
                              <td><hr style="height:1px;border-width:0;color:gray;background-color:gray"></td>
                              <td><hr style="height:1px;border-width:0;color:gray;background-color:gray"></td>
                            </tr>
                            <tr>
                                <td style="text-align: center">{{ shouldShowValue(rowIndex) ? dados.numLinha : '' }} </td>
                                <td style="text-align: left; width: 55%">{{ shouldShowValue(rowIndex) ? dados.registro : '' | truncate: 255 }} </td>
                                <td style="text-align: left"><li>{{ dados.mensagemErro | truncate: 255 }}</li></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </ng-template>
    </p-dialog>

</div>

