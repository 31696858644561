import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILogin } from 'src/app/@types/ILogin';
import { JwtHelperService } from '@auth0/angular-jwt';

const AUTH_ENDPOINT = `${environment.auth}`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ACCESS_TOKEN_KEY = '@PortalProjo:access_token';
  private readonly REFRESH_TOKEN_KEY = '@PortalProjo:refresh_token';

  constructor(private _http: HttpClient) {}

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  isFirstAccess(): boolean {
    const jwtHelper = new JwtHelperService();

    const token: any = jwtHelper.decodeToken(this.getToken()!) as any;

    const { user_first_access } = token;

    return user_first_access;
  }

  getToken(): string | null {
    return sessionStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  signIn(login: ILogin)  {
    return this._http.post(AUTH_ENDPOINT + '/securiy/login', login, { responseType: 'text' as const  })
      .pipe(
          tap(response => {
              const accessToken: string = response as unknown as string;
              sessionStorage.setItem("usuarioLogin", login.nome);
              this.initializeSession(accessToken);
          }),
          map(response => response as unknown as string)
      );
  }

  getVersion(): Observable<string> {
    return this._http.get<string>(AUTH_ENDPOINT + '/securiy/login/version');
  }


  obterUsuarioLogado(): string | null {
     return sessionStorage.getItem("usuarioLogin");
  }

  initializeSession(accessToken: any) {
    sessionStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
  }

  destroySession = (): void => {
    sessionStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }

}
