import { TipoAcesso, Usuario } from './../modelo/Usuario';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UsuarioService } from './usuario.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PageProps } from '../modelo/PageProps';
import { GruposService } from '../manutencao-grupo/grupos.service';
import { Grupo } from '../modelo/Grupo';
import { StatusUsuario } from '../modelo/Usuario'
import { catchError } from 'rxjs/operators';
import { interval, throwError } from 'rxjs';
import { AuthService } from '../core/auth/service/auth.service';

@Component({
  selector: 'app-manutencao-usuario',
  templateUrl: './manutencao-usuario.component.html',
  styleUrls: ['./manutencao-usuario.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class ManutencaoUsuarioComponent {
  //usuarios: any[] = [];
  usuarios: Usuario[] = [];
  usuario: Usuario = new Usuario();
  UsuarioPage: PageProps<Usuario> = {} as PageProps<Usuario>;

  grupo: Grupo = {};
  grupos: any[] = [];
  statusUsuario: any[] = [{ chave: '', valor: '' }];
  tipoAcesso: any[] = [{chave: "", valor: ""}];

  selectedUsuario: Usuario[] = [];

  dialog: boolean = false;
  visible: boolean = false;

  totalRecords!: number;
  firstPage = true;
  totalPages = 0;
  pageNumber = 0;
  pageSize = 5;

  loading: boolean = true;
  submitted!: boolean;
  lifeMessageToast: number = 7000;

  constructor(
    private service: UsuarioService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private serviceGrupo: GruposService,
    private auth: AuthService    
  ) {}

  ngOnInit() {
    this.fetchContent(this.pageNumber, this.pageSize);

    this.dialog = false;

    interval(5000) // Atualiza a cada 5 segundos (5000 milissegundos)
    .subscribe(() => this.fetchContent(this.pageNumber, this.pageSize)
    );

    this.getGrupos();
    for (const [values, keys] of Object.entries(StatusUsuario)) {
      this.statusUsuario.push({ chave: keys, valor: values });
    }

    for(const [values, keys] of Object.entries(TipoAcesso)) {
      this.tipoAcesso.push({chave: keys, valor: values});
    }
  }

  public fetchContent(page: number, rows: number): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getUsuarios(this.pageNumber, this.pageSize).subscribe({
      next: (payload) => {
        this.UsuarioPage = payload;
        this.usuarios = this.UsuarioPage.content;
        this.totalRecords = this.UsuarioPage.totalElements;
        this.totalPages = this.UsuarioPage.totalPages;
        this.pageSize = this.UsuarioPage.pageSize;
        this.pageNumber = this.UsuarioPage.currentPage;
        this.firstPage = this.UsuarioPage.firstPage;
      },
      error: (error: HttpErrorResponse) => {
        if (error.error.error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Usuario',
            detail: 'Erro ao tentar buscar lista de usuarios!',
            life: this.lifeMessageToast,
          });
        }
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  salvarUsuario() {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.submitted = true;
    console.log(this.usuario.grupo, 'grupo:', this.grupo);

    if (!this.usuario.id) {
      try {
        this.service.salavarUsuarios(this.usuario)
        .pipe(
          catchError(error => {
              console.error('Erro ao salvar o usuário:', error);
              if (!error.error.error)
                this.messageService.add({
                  severity: 'error',
                  summary: 'Usuário',
                  detail: 'Erro ao salvar usuário. ' + error.error,
                  life: this.lifeMessageToast,
                });
              return throwError(error);
          }))
        .subscribe((response) => {
          this.pageNumber = 0;
          this.pageSize = 5;
          console.log('entrei no if');
          this.fetchContent(this.pageNumber, this.pageNumber);
          this.visible = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Usuario',
            detail: 'Usuario salvo com sucesso!',
            life: this.lifeMessageToast,
          });
        });
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Usuario',
          detail: 'Erro ao tentar salvar o usuario!',
          life: this.lifeMessageToast,
        });
      }
    } else {
      try {
        console.log('editar usuario');
        if (this.usuario.id || this.usuario.nome != null) {
          this.service.editarUsuarios(this.usuario)
          .pipe(
            catchError(error => {
                console.error('Erro ao salvar o usuário:', error);
                if (!error.error.error)
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Usuário',
                    detail: 'Erro ao salvar usuário. ' + error.error,
                    life: this.lifeMessageToast,
                  });
                return throwError(error);
            }))
          .subscribe((response) => {
            this.pageNumber = 0;
            this.pageSize = 5;
            this.fetchContent(this.pageNumber, this.pageSize);
            this.messageService.add({
              severity: 'success',
              summary: 'Usuario',
              detail: 'Usuario salvo com sucesso!',
              life: this.lifeMessageToast,
            });
          });
          this.visible = false;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Usuário',
            detail: 'Campos obrigatórios não preenchidos!',
            life: this.lifeMessageToast,
          });
        }
      } catch (error){
        this.messageService.add({
          severity: 'error',
          summary: 'Usuário',
          detail: 'Erro ao tentar salvar usuário!',
          life: this.lifeMessageToast,
        });
      }
    }
    this.fetchContent(this.pageNumber, this.pageSize);
  }

  editarUsuario(usuario: Usuario) {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.usuario = { ...usuario };
    this.dialog = true;
    this.visible = true;
  }

  deletarUsuario(dados: any) {
    this.pageNumber = 0;
    this.pageSize = 5;
    console.log('cliquei')
    this.confirmationService.confirm({
      //target: event.target,
      header: 'Confirmação',
      message: 'Tem certeza que deseja deletar?',
      icon: 'pi pi-exclamation-triangle',
      blockScroll: true,
      dismissableMask: true,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptVisible: true,
      closeOnEscape: true,
      accept: () => {
        //this.selectedProjetos.forEach((item) => {
          console.log('item: ', dados);
          this.service
            .deletarUsuario(dados)
            .toPromise()
            .then((response) => {
              this.fetchContent(this.pageNumber, this.pageSize);
            });
        //});
        this.messageService.add({
          severity: 'success',
          summary: 'Projeto',
          detail: 'Projeto(os) deletado(s) com sucesso!',
          life: this.lifeMessageToast,
        });
      },
      reject: () => {
      }
    });
  }

  getGrupos() {
    this.serviceGrupo.getGruposAll(0,9999).subscribe(
      (response) => {
        console.log(response.content);
        this.grupos = this.grupos.concat(response.content);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getSatatus(){
  }

  getTipoAcesso() {
  }

  add() {
    this.usuario = new Usuario();
    this.visible = true;
    console.log('cliquei adicionar: ');
  }

  grupoSelecionado(e: any) {
    console.log('event', e.value);
    this.grupo = e.value;
  }

  //abre dialog
  openNew() {
    this.submitted = false;
    this.dialog = true;
  }

  //fecha dialog
  hideDialog() {
    this.visible = false;
    this.submitted = false;
  }

}
