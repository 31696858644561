import { Component, NgModule } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { Executivo } from '../modelo/Executivo';
import { ExecutivoService } from './executivo.service';
import { PageProps } from '../modelo/PageProps';
import { ProjetosService } from '../manutencao-projetos/projetos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Projeto } from '../modelo/Projeto';

@Component({
  selector: 'app-Executivo',
  templateUrl: './executivo.component.html',
  styleUrls: ['./executivo.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class ExecutivoComponent {
  Executivo : Executivo[] = [];
  projetosSelecionados: Projeto[] = [];

  projetos: Projeto[] = [];

  nome : string [] = [];
  firstPage = true;
  totalPages = 0;
  totalRecords!: number;
  pageNumber = 0;
  pageSize = 5;
  loading: boolean = true;

  ProjetosPage: PageProps<Projeto> = {} as PageProps<Projeto>;
  lifeMessageToast: number = 7000;

  constructor(
    private service: ExecutivoService,
    private serviceProjetos: ProjetosService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
  ){}

  async ngOnInit() {
    await this.fetchContent(this.pageNumber, this.pageSize);
    this.getProjetos();
    
  }

  getExecutivo(){
    this.service.getExecutivo(this.projetosSelecionados).subscribe(response => {
      console.log('Dados: ' + response);
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'relatorio_executiov_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + '.pdf'
      link.href = url;
      link.click();
      window.URL.revokeObjectURL(url); 
    })
  }

  getProjetos(){

    this.serviceProjetos.getProjetos().subscribe( {
      next: (payload) => {
        //console.log('tabela:', payload.content);
        this.ProjetosPage = payload;
        this.projetos = this.ProjetosPage.content;
        this.totalRecords = this.ProjetosPage.totalElements;
        this.totalPages = this.ProjetosPage.totalPages;
        this.pageSize = this.ProjetosPage.pageSize;
        this.pageNumber = this.ProjetosPage.currentPage;
        this.firstPage = this.ProjetosPage.firstPage;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Projetos',
          detail: 'Erro ao tentar buscar lista de projetos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    })

  }

  public fetchContent(page: number, rows: number): void {
    this.loading = true;
    this.serviceProjetos.getProjetos().subscribe({
      next: (payload) => {
        //console.log('tabela:', payload.content);
         //console.log('tabela:', payload.content);
         this.ProjetosPage = payload;
         this.projetos = this.ProjetosPage.content;
         this.totalRecords = this.ProjetosPage.totalElements;
         this.totalPages = this.ProjetosPage.totalPages;
         this.pageSize = this.ProjetosPage.pageSize;
         this.pageNumber = this.ProjetosPage.currentPage;
         this.firstPage = this.ProjetosPage.firstPage;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Projetos',
          detail: 'Erro ao tentar buscar lista de projetos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }


  paginateProjeto(e: any) {
    let pageIndex = e.first / e.rows;
    this.pageNumber = pageIndex;
    this.pageSize = e.rows;
    this.fetchContent(this.pageNumber, this.pageSize);
  }

}
