<app-menu-bar></app-menu-bar>
<BR>
    <div class="flex align-items-center">    
        <p-button icon="pi pi-refresh" (onClick)="onCheckboxChange($event)" />&nbsp;
        <p-checkbox [(ngModel)]="apenasAtivos" [binary]="true" label="Apenas ativos"/>
    </div>

<table>
    <tr>
        <td width="33%">
            <!-- Resumo de Projeto por Status -->
            <p-chart height="250px" type="pie" [data]="projetoStatusData" [options]="projetoStatusOptions" />
        </td>
        <td width="33%">
            <!-- Resumo de Chamados por Status -->
            <p-chart height="250px" type="pie" 
            [data]="chamadoStatusData" [options]="chamadoStatusOptions" />
        </td>
        <td width="33%">
            <!-- Resumo de Escalation por Status -->
            <p-chart height="250px" type="pie" 
            [data]="escalationStatusData" [options]="escalationStatusOptions" />
        </td>
    </tr>
    <tr>
        <td><hr></td>
        <td><hr></td>
        <td><hr></td>
    </tr>    
    <tr>
        <td><p-chart height="300px" type="line" [data]="projetoMensalData" [options]="projetoMensalOptions"/></td>
        <td><p-chart height="300px" type="line" [data]="chamadoMensalData" [options]="chamadosMensalOptions"/></td>
        <td><p-chart height="300px" type="line" [data]="escalationMensalData" [options]="escalationMensalOptions"/></td>
    </tr>
    <tr>
        <td><hr></td>
        <td><hr></td>
        <td><hr></td>
    </tr>    
    <tr>
        <td><p-chart height="300px" type="bar" [data]="projetoGrupoData" [options]="projetoGrupoOptions"/></td>
        <td><p-chart height="300px" type="bar" [data]="chamadoGrupoData" [options]="chamadosGrupoOptions"/></td>
        <td><p-chart height="300px" type="bar" [data]="escalationGrupoData" [options]="escalationGrupoOptions"/></td>
    </tr>    
    <tr>
        <td><hr></td>
        <td><hr></td>
        <td><hr></td>
    </tr>
    <tr>
        <td><p-chart height="300px" type="bar" [data]="projetoProdutoData" [options]="projetoProdutoOptions"/></td>
        <td><p-chart height="300px" type="bar" [data]="chamadoProdutoData" [options]="chamadosProdutoOptions"/></td>
        <td><p-chart height="300px" type="bar" [data]="escalationProdutoData" [options]="escalationProdutoOptions"/></td>
    </tr>   
    <tr>
        <td><hr></td>
        <td><hr></td>
        <td><hr></td>
    </tr>
    <tr>
        <td><p-chart height="300px" type="bar" [data]="projetoProdutoDuracaoData" [options]="projetoProdutoDuracaoOptions"/></td>
        <td><p-chart height="300px" type="bar" [data]="chamadoProdutoDuracaoData" [options]="chamadosProdutoDuracaoOptions"/></td>
        <td><p-chart height="300px" type="bar" [data]="escalationProdutoDuracaoData" [options]="escalationProdutoDuracaoOptions"/></td>
    </tr>

</table>

