  import { Component } from '@angular/core';
  import { ConfirmationService, MessageService } from 'primeng/api';
  import { FileUpload, FileUploadModule } from 'primeng/fileupload';
  import { ChamadosCargaService } from './carga.service';
  import { AuthService } from 'src/app/core/auth/service/auth.service';
  import { PageProps } from '../modelo/PageProps';
  import { HttpErrorResponse } from '@angular/common/http';
  import { interval, throwError } from 'rxjs';

  @Component({
    selector: 'app-carga',
    templateUrl: './carga.component.html',
    styleUrls: ['./carga.component.css'],
    providers: [MessageService, ConfirmationService, FileUploadModule],
  })
  export class ChamadosCargaComponent {

          erros: any[] = [];
          errosDialog: boolean = false;
          lifeMessageToast: number = 7000;
          loading: boolean = false;
          processamentos: any [] = [];
          ProcessamentoPage: PageProps<any> = {} as PageProps<any>;
          uploadDialog: boolean = false;
          uploadFiles: any[] = [];

          constructor(
            private auth: AuthService,
            private service: ChamadosCargaService,
            private messageService: MessageService
            ) {}

            async ngOnInit() {
              await this.fetchContent();

              interval(5000) // Atualiza a cada 5 segundos (5000 milissegundos)
              .subscribe(() => {
              this.fetchContent();
                }
              );
            }

            public fetchContent(): void {
              if (!this.auth.isAuthenticated())
                return;
              this.loading = true;
              this.service.getProcessamentos().subscribe({
                next: (payload) => {
                  this.ProcessamentoPage = payload;
                  this.processamentos = this.ProcessamentoPage.content;
                  this.loading = false;
                },
                error: (error: HttpErrorResponse) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Projetos',
                    detail: 'Erro ao tentar buscar lista de processamentos',
                    life: this.lifeMessageToast,
                  });
                  this.loading = false;
                },
              });
            }

          openUpload() {
              this.uploadDialog = true;
            }

          listaErros(processamento: any) {
            this.errosDialog = true;
            if (!this.auth.isAuthenticated())
            return;
          this.loading = true;
          this.service.getErros(processamento.id).subscribe({
            next: (payload) => {
              this.ProcessamentoPage = payload;
              this.erros = this.ProcessamentoPage.content;
              this.loading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Projetos',
                detail: 'Erro ao tentar buscar lista de erros',
                life: this.lifeMessageToast,
              });
              this.loading = false;
            },
          });
          }

          uploadFile(event: any) {
              this.loading = true;
              for (let file of event.files) {
                console.info(file);
                this.service.uploadFile(file).subscribe({
                    next: (response) => {
                        this.messageService.add({
                          severity: 'success',
                          summary: 'Upload',
                          detail: 'Upload concluido;',
                          life: this.lifeMessageToast});
                        this.uploadDialog = false;
                    },
                    error: (err) => {
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Upload',
                        detail: 'Erro ao processar o arquivo ' + file.name + ' - ' + err.error,
                        life: this.lifeMessageToast,
                      });
                    },
                  });
                }
              this.loading = false;
          }

          shouldShowValue(rowIndex: number): boolean {
              if (rowIndex === 0)
                return true; // Always show the first row
              const currentRow = this.erros[rowIndex];
              const previousRow = this.erros[rowIndex - 1];
              return currentRow.numLinha !== previousRow.numLinha;
          }

        }
