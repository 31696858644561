import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Ocorrencia } from '../modelo/Ocorrencia';
import { Observable } from 'rxjs';
import { Projeto } from '../modelo/Projeto';

@Injectable({
    providedIn: 'root'
  })
  export class OcorrenciaService {

    apiUrl: string = `${environment.backendUrl}/ocorrencias`;

    constructor(private http: HttpClient) { }
  
      httpOptions = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
          }
      }
  
      httpOptionsMultiPart = {
        headers: {
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    getPorProjeto(projetoId: String | undefined | null) {
      if (projetoId == undefined || projetoId == null)
        return this.http.get<Ocorrencia[]>(`${this.apiUrl}`,
        { headers: this.httpOptions.headers });
      else
        return this.http.get<Ocorrencia[]>(`${this.apiUrl}/${projetoId}`,
        { headers: this.httpOptions.headers });

    }

    get(){
        return this.http.get<Ocorrencia[]>(`${this.apiUrl}`,
        { headers: this.httpOptions.headers });
      }

    put( ocorrencia: Ocorrencia): Observable<any>{
        return this.http.put(`${this.apiUrl}/${ocorrencia.id}`, 
        ocorrencia, { headers: this.httpOptions.headers });
      }
  
  }
