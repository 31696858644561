export class ProjetoAud {
  public idrev?: number;
  public id?: number;
  public usuario?: string;
  public operacao?: string;
  public data?: Date;
  public detalhe?: string;
  public grupo?: string;
  public operacaoDescricao?: string;
}

// export enum TipoOperacao {
//   INSERT = 'INCLUSÃO',
//   DELETE = 'EXCLUSÃO',
//   UPDATE = 'ALTERAÇÃO',
// }
