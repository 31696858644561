
export class GrupoAud {

public id?: number
public idrev?: number
public usuario?: string
public operacao?: string
public data?: Date
public detalhe?: string

}
