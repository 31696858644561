import { Upload } from './../modelo/Upload';
import { Status } from './../modelo/Status';
import { ProjetoAud } from './../modelo/ProjetoAud';
import { ProjetosService } from './projetos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Projeto } from '../modelo/Projeto';
import { PageProps } from '../modelo/PageProps';
import { Grupo } from '../modelo/Grupo';
import { GruposService } from '../manutencao-grupo/grupos.service';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Comentario } from '../modelo/Comentario';
import { catchError } from 'rxjs/operators';
import { interval, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth/service/auth.service';
import { SharedDataService } from '../shared/shared-data/shared-data.service';

type DataType = { label: string, value: string }

@Component({
  selector: 'app-manutencao-projetos',
  templateUrl: './manutencao-projetos.component.html',
  styleUrls: ['./manutencao-projetos.component.css'],
  providers: [MessageService, ConfirmationService, FileUploadModule],
})
export class ManutencaoProjetosComponent {
  @ViewChild(FileUpload) fileUpload!: FileUpload;

  filtroData: Date[] = [];
  tipoFiltro: string = '';

  tipoDatas: any = [
    { name: "Inclusão", value: "cadastro"},
    { name: "Atualização", value: "atualizacao"},
    { name: "Assinatura Contrato", value: "contrato"},
    { name: "Kickoff", value: "kickoff"},
    { name: "Teste Unitário", value: "unitario"},
    { name: "Teste Integrado", value: "integrado"},
    { name: "Go-live Previsto", value: "golive"},
    { name: "Promoção para Produção", value: "promocao"}
  ];

  projeto: Projeto = new Projeto();

  grupo: Grupo = {} = new Grupo();

  projetoAud: ProjetoAud = new ProjetoAud();
  projetosAud: any[] = [];

  status: any[] = [];
  grupos: any[] = [];
  solucoes: any[] = [];
  origens: any[] = [];

  selectedProjetos: Projeto[] = [];
  projetos: Projeto[] = [];
  ProjetoPage: PageProps<Projeto> = {} as PageProps<Projeto>;

  dialogHistorico: boolean = false;
  selectedHistorico: ProjetoAud[] = [];
  historico: ProjetoAud[] = [];
  historicoPage: PageProps<ProjetoAud> = {} as PageProps<ProjetoAud>;

  dialog: boolean = false;
  visible: boolean = false;

  comentarioDialog: boolean = false;
  comentario: Comentario = new Comentario();
  selectedComentario: Comentario[] = [];
  comentarios: any[] = [];

  uploadDialog: boolean = false;
  upload: Upload = new Upload();
  selectedUpload: Upload [] = [];
  uploads: any[] = [];
  uploadFiles: any[] = [];
  processandoArquivo: boolean = false;

  totalRecords!: number;
  firstPage = true;
  totalPages = 0;
  pageNumber = 0;
  pageSize = 10;

  totalRecordsHistorico!: number;
  firstPageHistorico = true;
  totalPagesHistorico = 0;
  pageNumberHistorico = 0;
  pageSizeHistorico = 10;

  loading: boolean = false;
  submitted!: boolean;
  submittedGrupo!: boolean;
  submittedCliente!: boolean;
  submittedSolucao!: boolean;
  submittedStatus!: boolean;
  lifeMessageToast: number = 7000;

  constructor(
    private service: ProjetosService,
    private serviceGrupo: GruposService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private sharedData: SharedDataService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    await this.fetchContent();

    this.dialog = false;

    this.getGrupos();
    this.getSolucoes();
    this.getOrigens();

//    interval(60000) // Atualiza a cada  5 segundos (5000 milissegundos)
//    .subscribe(() => {
//    this.fetchContent(this.pageNumber, this.pageSize);
//      }
//    );

    for (const [values, keys] of Object.entries(Status)) {
      this.status.push({ chave: keys, valor: values });
    }
  }

  public fetchContent(): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getProjetosFiltro(this.tipoFiltro, this.filtroData).subscribe({
      next: (payload) => {
        this.ProjetoPage = payload;
        this.projetos = this.ProjetoPage.content;
        this.totalRecords = this.ProjetoPage.totalElements;
        this.totalPages = this.ProjetoPage.totalPages;
        this.pageSize = this.ProjetoPage.pageSize;
        this.pageNumber = this.ProjetoPage.currentPage;
        this.firstPage = this.ProjetoPage.firstPage;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Projetos',
          detail: 'Erro ao tentar buscar lista de projetos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  public fetchContentHistorico(id: number, page: number, rows: number) {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getHistorico(id, this.pageNumber, this.pageSize).subscribe({
      next: (payload) => {
        this.historicoPage = payload;
        this.projetosAud = this.historicoPage.content;
        this.totalRecords = this.historicoPage.totalElements;
        this.totalPages = this.historicoPage.totalPages;
        this.pageSize = this.historicoPage.pageSize;
        this.pageNumber = this.historicoPage.currentPage;
        this.firstPage = this.historicoPage.firstPage;
        this.projetosAud = payload.content;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Projetos',
          detail: 'Erro ao tentar buscar histórico de alteração!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  public listarComentarios() {
    this.loading = true;
    this.service.listarComentarios(this.comentario).subscribe({
      next: (payload) => {
        console.log(payload);
        this.comentarios = payload.content;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Comentario',
          detail: 'Erro ao tentar buscar comentarios',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
  }

  public listarArquivos() {
    this.loading = true;
    this.service.listarArquivos(this.upload).subscribe({
      next: (payload) => {
        console.log('Lista arquivos:', payload);
        this.uploads = payload.content;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Upload',
          detail: 'Erro ao tentar buscar uploads',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
  }

  formataDate(data: any){
    return Intl.DateTimeFormat('pt-BR').format(new Date(data));
  }

  editarProjeto(projeto: Projeto) {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.projeto = { ...projeto };
    this.dialog = true;
    this.visible = true;
  }

  salvarProjeto() {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.submitted = true;
    this.submittedCliente = true;
    this.submittedGrupo = true;
    this.submittedSolucao = true;
    this.submittedStatus = true;
    if (this.projeto.id) {
      try {
        this.service.editarProjeto(this.projeto)
        .pipe(
          catchError(error => {
              console.error('Erro ao salvar o projeto:', error);
              if (!error.error.error)
                this.messageService.add({
                  severity: 'error',
                  summary: 'Grupo',
                  detail: 'Erro ao salvar projeto. ' + error.error,
                  life: this.lifeMessageToast,
                });
              return throwError(error);
          }))
        .subscribe((response) => {
          this.pageNumber = 0;
          this.pageSize = 5;
          this.messageService.add({
            severity: 'success',
            summary: 'Projeto',
            detail: 'Projeto atualizado com sucesso!',
            life: this.lifeMessageToast,
          });
        });
        this.visible = false;
        this.fetchContent();
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Projeto',
          detail: 'Erro ao tentar atualizar Projeto!',
          life: this.lifeMessageToast,
        });
      }
    } else {
      try {
        console.log('salvar');
        this.projeto.grupo = this.grupo;

        if (this.projeto.grupo.nome != null && this.projeto.cliente
          != null && this.projeto.solucao != null && this.projeto.status != null) {
          this.service.salvarProjeto(this.projeto)
          .pipe(
            catchError(error => {
                console.error('Erro ao salvar o projeto:', error);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Projeto',
                  detail: 'Erro ao salvar projeto! ' + error,
                  life: this.lifeMessageToast,
                });
                return throwError(error);
            })
          )
          .subscribe((response) => {
            this.pageNumber = 0;
            this.pageSize = 5;
            this.messageService.add({
              severity: 'success',
              summary: 'Projeto',
              detail: 'Projeto salvo com sucesso!',
              life: this.lifeMessageToast,
            });
          });
          this.fetchContent();
          this.visible = false;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Projeto',
            detail: 'Campos obrigatórios não preenchidos!',
            life: this.lifeMessageToast,
          });
          return;
        }
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Projeto',
          detail: 'Erro ao tentar salvar Projeto!',
          life: this.lifeMessageToast,
        });
      }
    }
  }

  openHistorico(dados: any) {
    this.projeto = {...dados};
    this.fetchContentHistorico(this.projeto.id!, this.pageNumber, this.pageSize);
    this.dialogHistorico = true;
  }

  hideHistorico() {
    this.dialogHistorico = false;
  }

  deletarProjeto(dados: any) {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.confirmationService.confirm({
      //target: event.target,
      header: 'Confirmação',
      message: 'Tem certeza que deseja deletar?',
      icon: 'pi pi-exclamation-triangle',
      blockScroll: true,
      dismissableMask: true,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptVisible: true,
      closeOnEscape: true,
      accept: () => {
        //this.selectedProjetos.forEach((item) => {
        console.log('item: ', dados);

        this.service
        .deletarProjeto(dados)
        .toPromise()
        .then((response) => {
          this.fetchContent();

          this.messageService.add({
            severity: 'success',
            summary: 'Projeto',
            detail: 'Projeto(os) deletado(s) com sucesso!',
            life: this.lifeMessageToast,
          });
          this.fetchContent();
        })
        .catch((error) => {
          console.error('Erro ao deletar projeto:', error);

          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao deletar projeto',
            detail: error.error,
            life: this.lifeMessageToast,
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Projeto',
          detail: 'Operação cancelada!',
          life: this.lifeMessageToast,
        });
      },
    });
  }

  getSolucoes() {
    this.service.getSolucoes().subscribe(
      (response) => {
        this.solucoes = this.solucoes.concat(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getOrigens() {
    this.service.getOrigens().subscribe(
      (response) => {
        this.origens = this.origens.concat(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getGrupos() {
    this.serviceGrupo.getGruposAll(0,9999).subscribe(
      (response) => {
        this.grupos = this.grupos.concat(response.content);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  add() {
    this.projeto = new Projeto();
    this.visible = true;
  }

  addEdit(projeto: Projeto) {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.projeto = { ...projeto };
    this.dialog = true;
    this.visible = true;
  }

  modificaPesquisaSatisfacao(projeto: Projeto) {
    this.confirmationService.confirm({
      header: 'Confirmação',
      message: 'Tem certeza que deseja confirmar/cancelar a Peesquisa de Satisfaçao ?',
      icon: 'pi pi-exclamation-triangle',
      blockScroll: true,
      dismissableMask: true,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptVisible: true,
      closeOnEscape: true,
      accept: () => {
        this.service
        .modificaPesquisaSatisfacao(projeto)
        .toPromise()
        .then((response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Projeto',
            detail: 'Pesquisa de Satisfaçao modificada com sucesso!',
            life: this.lifeMessageToast,
          });
          this.fetchContent();

        })
        .catch((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível modificar pesquisa de satisfaçao.',
            detail: error.error,
            life: this.lifeMessageToast,
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Projeto',
          detail: 'Operação cancelada!',
          life: this.lifeMessageToast,
        });
      },
    });

    }

  validaEncerramento(projeto: Projeto) {
    this.confirmationService.confirm({
      header: 'Confirmação',
      message: 'Tem certeza que deseja validar o projeto ?',
      icon: 'pi pi-exclamation-triangle',
      blockScroll: true,
      dismissableMask: true,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptVisible: true,
      closeOnEscape: true,
      accept: () => {
        this.service
        .validaEncerramento(projeto)
        .toPromise()
        .then((response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Projeto',
            detail: 'Projeto validado com sucesso!',
            life: this.lifeMessageToast,
          });
          this.fetchContent();

        })
        .catch((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível validar o projeto.',
            detail: error.error,
            life: this.lifeMessageToast,
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Projeto',
          detail: 'Operação cancelada!',
          life: this.lifeMessageToast,
        });
      },
    });
  }

  origemSelecionado(e: any) {
    console.log('event', e);
    this.projeto.origem = e.value;
  }

  solucaoSelecionado(e: any) {
    console.log('event', e);
    this.projeto.solucao = e.value;
  }

  grupoSelecionado(e: any) {
    console.log('event', e);
    this.grupo = e.value;
  }

  hideDialog() {
    this.visible = false;
    this.submitted = false;
    this.pageNumber = 0;
    this.pageSize = 5;
    this.fetchContent();
  }

  //dialog do botão de comentarios
  inserirComentario() {
    this.comentarioDialog = false;
    this.comentario.projeto = this.projeto;
    try {
      if (this.comentario.detalhe !== null) {
        this.projeto = this.projeto;
        this.service
          .inserirComentario(this.comentario)
          .subscribe((response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Comentario',
              detail: 'Comentario inserido!',
              life: this.lifeMessageToast,
            });
          });
        this.comentario.detalhe = '';
        this.fetchContent();
        this.comentarioDialog = false;
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Comentário',
          detail: 'Campo não preenchido!',
          life: this.lifeMessageToast,
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Comentario',
        detail: 'Erro ao inserir comentario!',
        life: this.lifeMessageToast,
      });
    }
  }

  openOcorrencia(projeto: Projeto) {
    this.projeto = { ...projeto };
    this.sharedData.setProjeto(projeto.id);
    const url = '/ocorrencia';
    window.open(url, '_blank');
  }

  openComentario(projeto: Projeto) {
    this.projeto = { ...projeto };
    this.comentario.projeto = { ...this.projeto };
    this.comentarioDialog = true;
    this.listarComentarios();
  }

  hideComentario() {
    this.comentarioDialog = false;
    this.pageNumber = 0;
    this.pageSize = 5;
  }

  //dialog do botão de anexo
  uploadFile(event: any) {
    this.loading = true;
    this.upload.projeto = this.projeto;
    this.processandoArquivo = true;
    try {
      for (let file of event.files) {
        this.projeto = this.projeto;
        this.service.uploadFile(file, this.upload).subscribe({
          next: (response) => {
            console.log('Status: ' + response.status);
              this.messageService.add({
                severity: 'success',
                summary: 'Upload',
                detail: 'Upload concluido;',
                life: this.lifeMessageToast,
              });
              this.fileUpload.clear();
              this.fetchContent();
              this.uploadDialog = false;
            //}
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Upload',
              detail: 'Erro: ' + err.error,
              life: this.lifeMessageToast,
            });
          },
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Upload',
        detail: 'Erro ao tentar fazer Upload!',
        life: this.lifeMessageToast,
      });
    }
//    this.loading = false;
//    this.fileUpload.clear();
//    this.uploadDialog = false;
//    this.fetchContent(this.pageNumber, this.pageSize);
  }

  uploadError(event: any) {
    console.log('Erro no upload!');
    this.fileUpload.clear();
  }

  openUpload(projeto: Projeto) {
    this.projeto = { ...projeto };
    this.upload.projeto = { ...this.projeto };
    this.uploadDialog = true;
    this.listarArquivos();
  }

  hideUpload() {
    this.uploadDialog = false;
    this.pageNumber = 0;
    this.pageSize = 5;
  }

  //fazer download
  downloadArquivo(upload: Upload) {
    this.upload = { ...upload };
    this.service.downloadFile(this.upload).subscribe((response) => {
      console.log('response', response);
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = this.upload.nome!;
      link.href = url;
      link.click();
      window.URL.revokeObjectURL(url); // Libera a URL criada
      this.loading = false;
    });
    this.loading = false;
  }

  paginateProjeto(e: any) {
    let pageIndex = e.first / e.rows;
    this.pageNumber = pageIndex;
    this.pageSize = e.rows;
  }

  getIconClass(status: boolean): String | undefined | null {
    if (status)
      return 'pi pi-check p-button-success';
    return 'pi pi-times p-button-danger';
  }


}
