import { Injectable } from '@angular/core';
import { Projeto } from 'src/app/modelo/Projeto';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private data: any;

  setProjeto(projetoId?: number) {
    localStorage.setItem("projeto.id", String(projetoId));
  }

  getProjetoId(): String | null {
    return localStorage.getItem("projeto.id");
  }

  cleanProjetoId() {
    localStorage.removeItem("projeto.id");
  }

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }
}
