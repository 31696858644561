export enum RegraTipoData {

    DATA_CADASTRO = "Data de Cadastro",
    CONTRATO = "Data Assinatura de Contrato",
    KICKOFF = "Data Prevista de Kickoff",
    TESTUNITARIO = "Data Prevista de Teste Unitario",
    TESTINTEGRADO = "Data Prevista de Teste Integrado",
    GOLIVE = "Data Prevista de Go Live",
    DATA_SISTEMA = "Data Atual do Sistema",
    DATA_PROMOCAO = "Data de Promocao",
    DATA_ATUALIZACAO = "Data de Atualizaçao"

}
