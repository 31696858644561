import { IApiResponse } from "../modelo/PageProps";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ChamadoDadosService {

    apiUrl: string = `${environment.backendUrl}/chamados`;

constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }
  
httpOptionsMultiPart = {
        headers: {
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    getDados(){
        let searchParams = new HttpParams();
        return this.http.get<IApiResponse>(`${this.apiUrl}`,
        { params: searchParams, headers: this.httpOptions.headers });
      }

      deletarChamado(id: number): Observable<any> {
        return this.http.delete(`${this.apiUrl}/${id}`, this.httpOptions);
      }


    }